import { Link } from "gatsby"
import React from "react"
import moment from "moment"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HubspotForm from "react-hubspot-form"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import defaultImg from "../images/default-cover-1.jpg"
 import useLiveControl from "../controlled/useLiveControl"
//  import LiveControlSpinner from "../components/liveControlSpinner"

const UltimateGuide = () => {

    const { isStaging } = useLiveControl()

    return (
        <Layout>
            <Seo
                title={"The Ultimate Guide to Growing Your Home Improvement Business"}
                description={"EnerBank USA is America's home improvement lender of choice. Learn how we help contractors grow their business and homeowners have the home of their dreams."
            } />
          <Helmet>
            <body id={`${isStaging ? "rebrand" : null }`} className="ultimate-guide" />
          </Helmet>
          {isStaging ? (
            <section 
            className="banner-area"
            id="homeowner-page-banner-section">
                <div className="container banner-bg"
                style={{
                    backgroundImage:
                    "url(" +
                    "http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/ultimate-guide-hero.jpg" +
                    ")",
                }}
                ></div>
            <div className="container banner-container">
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                        <div className="identifier-container row-fluid">
                            <div className="element span12">
                                <div className="rhif-identifier span5">
                                    <h4>HOME IMPROVEMENT FINANCING</h4>
                                </div>
                                <div className="right-angle span2"></div>
                            </div>
                        </div>
                        <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                            The Ultimate Guide to growing your<br/> home improvement business
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container text-center mobile">
                <h1 className="text-white text-left" style={{ padding: "80 0" }}>
                    The Ultimate Guide to Growing Your Home Improvement Business
                </h1>
            </div>
        </section>
    ) : (
       <section className="hero-section page-hero"
            style={{
                backgroundImage:
                "url(" +
                "http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/ultimate-guide-hero.jpg" +
                ")",
                backgroundPosition: "50% 25%",
            }}
          >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="left-text">
                            <h1>The Ultimate Guide to Growing Your Home Improvement Business</h1>
                            <p className="text-white">From marketing tips to new ways to grow your bottom line, we have the resources to help you grow. </p>
                        </div> 
                    </div>
                    <div className="col-lg-7 mb-5 mb-lg-0 pl-0 pr-0 empty-col">
                    </div>
                </div>
            </div>
          </section>
        )}
          <section id="increase-leads" className={`increase-leads ${isStaging ? "pt-2" : null }`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center pb-5">
                        {isStaging ? (
                            <h3 className="pb-5">From Marketing Tips to New Ways to Grow Your Bottom Line,<br/> We Have the Resources to Help You Grow. </h3>
                        ) : (
                            null
                        )}
                        <h2>Our upcoming Ultimate Guide will be the <br/>complete collection to all our mini guides.</h2>
                        <p className="mb-3">We&apos;ve already released the first six chapters, and we plan on releasing only one more before the Ultimate Guide is fully released!</p>
                        <p> <strong>Download our newest mini guide on boosting the close rate in your home improvement business.<br/> And scroll down to download our previous mini guides.</strong></p>
                    </div>
                    <div className="col-lg-4 pb-3">      
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/close-that-deal.png" alt="Clost that Deal" />    

                    </div>
                    <div className="col-md-6">  
                        <HubspotForm
                        portalId="381510"
                        formId="37ef92de-055d-49f7-b562-1ba9da90a529"
                        onSubmit={() => this.handleFormSubmit}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                        />
                        <p className="pt-3">Our new mini guide on boosting your close rate can help with simplifying the signing process, strategy, gaining insights, and more!</p>

                    </div>
                </div>
            </div>
          </section>
          <section id="rebrand" className="mini-guides bg-light-gray pt-0">
            <div className="regions-green py-4">
                <div className="container">
                    <h2 className="text-center text-white">Over 2,000 contractors have already downloaded our guides</h2>
                </div>
            </div>
            <div className="container"> 
                <div className="row justify-content-center">
                    <div className="col-12 text-center pb-5 pt-60">
                        <h2 className="mb-3">Contractors love our resources — check it out for yourself</h2>
                        <h3 className="pb-3">Download Our Other free Mini Gides Now</h3>
                    </div>
                    
                    </div>
                    <div className="row multi-cards justify-content-center">
                        <div className="col-md-3">
                            <Link className="card-link" to="https://info.enerbank.com/look-at-you-grow?utm_medium=organic&utm_source=internal&utm_campaign=Ultimate+Guide+EB+Page&utm_content=grow+av+proj+size+guide&utm_term=Inbound">
                                <div className="card"> 
                                    <div className="card-image">
                                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/04/look-at-you-grow.jpg" alt="Look at you grow" />
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Look At You Grow: A Quick Guide to Increasing Average Project Size</h3>
                                    </div>
                                
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link className="card-link"
                                    to="https://info.enerbank.com/digital-marketing-blueprints/?utm_medium=organic&utm_source=internal&utm_campaign=Ultimate+Guide+EB+Page&utm_content=Digital+Marketing+Blueprints&utm_term=Inbound"
                                >
                                <div className="card"> 
                                    <div className="card-image">
                                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/04/digital-marketing-blue-prints.jpg" alt="digital marketing blueprints" />
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">FREE BLUEPRINTS for Your Digital Marketing Strategy</h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link className="card-link" to="https://info.enerbank.com/brand-awareness-handbook?utm_medium=organic&utm_source=internal&utm_campaign=Ultimate+Guide+EB+Page&utm_content=Brand+Awareness+Guide&utm_term=Inbound" >
                                <div className="card"> 
                                    <div className="card-image">
                                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/04/brand-awareness.jpg" alt="brand awareness" />
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Building Your Brand:<br/> A Free Guide</h3>
                                    </div>                              
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link className="card-link" to="https://info.enerbank.com/increasing_leads?utm_medium=feed&utm_source=internal&utm_campaign=Ultimate+Guide+Page&utm_content=Organic+Click&utm_term=Inbound" >
                                <div className="card"> 
                                    <div className="card-image">
                                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/increasing-leads-the-life-blood-of-your-contractor-business.jpg" />
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Increasing Leads: The Lifeblood of Your Contractor Business</h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>  
                <div className="row py-5">
                    <div className="col-md-12 text-center">
                        <p>When you <Link className="eb-link" to="#increase-leads" >fill out the form above</Link>, you&apos;ll be among the first notified when we publish new chapters and when the full guide is complete!<br/> Check out what&apos;s planned: </p>
                    </div>
                </div>
                <div className="row justify-content-center icon-cards pt-4">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-image">
                                <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Reduce-cancellations_1.png"/>
                            </div>
                            <div className="card-body">
                                <h3>The Secrets to<br/> Reducing Cancelations</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="regions-green blog-feed">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h2 className="text-white text-center
                        ">About Regions Home Improvement Financing</h2>
                        <p className="text-white">For over two decades, Regions Home Improvement Financing has been a leader in the home improvement lending industry. With over $14 billion funded, we&apos;re helping homeowners around the country get the home of their dreams while helping contractors grow their business in a simple and effective way. Our loans are built specifically for the home renovation industry, meaning there is a fit for both your trade and your customer&apos;s budget. Plus, with technology designed and developed for contractors, both you and your customer can have a convenient and easy experience.</p>
                    </div>
                </div>
            </div>

          </section>
        </Layout>
    )

}
export default UltimateGuide

